/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}  */
@font-face {
  font-family: 'PSLxText Bold';
  src: local("PSLxText Bold"), url('./fonts/PSLxText Bold.ttf') format('truetype');
}

body {
  /* The image used */
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-image: url('/assets/images/background.jpg');
  background-repeat: no-repeat;
  background-size: cover; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* -ms-transform: translate(-50%, -50%); */
  /* -webkit-transform: translate(-50%, -50%); */
}

body [class*="swal2-container"] {
  z-index: 9999;
}
