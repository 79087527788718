.data1 {
  /* background-color: #0160b2; */
  /* border: 5px solid red; */
  border: none;
  background-color: #d5eef6;
  border-radius: 10px;
  padding: 10px;
}

.data2 {
  /* background-color: #0160b2; */
  /* border: 5px solid red; */
  border: none;
  background-color: #fff0c6;
  border-radius: 10px;
  padding: 10px;
}

/* Style all font awesome icons */
.myimagebtn {
  text-decoration: none;
}

/* Add a hover effect if you want */
.myimagebtn:hover {
  opacity: .7;
  cursor: pointer;
}

.div {
  border: solid 1px red !important;
}

.myimage {
  width: 100%;
  height: 100%;
  padding: 0;
}

/* Style all font awesome icons */
.my-btn {
  text-decoration: none;
}

/* Add a hover effect if you want */
/* .my-btn:hover {
  opacity: 0.7;
  cursor:url(/images/mouse.png),auto;
}

input:hover {
  cursor:url(/images/mouse.png),auto;
}

img:hover {
  cursor:url(/images/mouse.png),auto;
}

span:hover {
  cursor:url(/images/mouse.png),auto;
}

button {
  cursor:url(/images/mouse.png),auto !important;
} */

.img_center {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.intro {
  background-image: url('./images/background.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.my-modal {
  /* position: fixed; */
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
  background: rgba(0, 0, 0, 0.6);
}

.my-modal-main {
  /* position: fixed; */
  position: absolute;
  background: white;
  width: 80%;
  height: 80%;
  /* top: 50%;
  left: 50%; */
  transform: translate(10%, 10%);
}

.app-font-face {
  font-family: 'PSLxText Bold';
}

.text {
  text-shadow:
    #fff 0.1vh 0.1vh 0px, #fff -0.1vh -0.1vh 0px, #fff 0px -0.16vh 0px, #fff 0px 0.16vh 0px;
  font-weight: bolder;
}

